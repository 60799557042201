import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import styled, { css } from 'styled-components';
const Item = styled.li `
  display: block;
  height: 45px;
  margin: 0;
  padding: 0;

  @media ${until(Device.TabletLarge)} {
    margin-bottom: 3px;
  }
`;
const Link = styled.a `
  position: relative;
  display: block;
  background: linear-gradient(to bottom, #e5e5e5, #e5e5e5) no-repeat;
  background-size: 0% 100%;
  color: #000;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 13px 20px 12px;
  text-decoration: none;
  transition: background-color 0.15s ease-out, background-size 0.15s ease-out, color 0.15s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: linear-gradient(to bottom, #6f9d20, #6f9d20) no-repeat;
    background-size: 100% 0%;
    height: 100%;
    transition: background-size 0.15s ease-out;
    width: 2px;
  }

  &:focus,
  &:hover {
    background-size: 100% 100%;
    color: #000;
    text-decoration: none;

    &::before {
      background-size: 100% 100%;
    }
  }

  ${({ isActive }) => isActive &&
    css `
      background-size: 100% 100%;
      color: #000;
      text-decoration: none;

      &::before {
        background-size: 100% 100%;
      }
    `}

  @media ${until(Device.TabletLarge)} {
    background: #f6f6f6;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export default {
    Item,
    Link,
};
