import React from 'react';
import S from './NavigationMobile.styles';
const NavigationMobile = ({ activeId, items = [], isPortalHeader = false, onItemClick, }) => {
    const flattened = flatMapRecursive(items, (item) => item.children ?? []);
    const activeItem = flattened.find((item) => item.id === activeId);
    const activeItemChildren = activeItem?.children;
    const activeItemParent = flattened.find((item) => item.children?.find((child) => child.id === activeId));
    return (React.createElement(S.Container, { key: activeItem?.id ?? '0' },
        activeItem && (React.createElement(React.Fragment, null,
            React.createElement(S.BackButton, { onClick: (event) => {
                    event.preventDefault();
                    onItemClick(activeItemParent?.id ?? '');
                } },
                React.createElement(S.BackArrow, null),
                activeItemParent?.title ? `Back to ${activeItemParent?.title}` : 'Back'),
            React.createElement(S.Title, { href: activeItem.url }, activeItem.title))),
        (activeItemChildren ?? items).map((item) => (React.createElement(S.Item, { key: item.id }, (item.children?.length && !isPortalHeader) ? (React.createElement(S.ItemButton, { onClick: (event) => {
                event.preventDefault();
                onItemClick(item.id);
            } },
            item.title,
            React.createElement(S.ItemArrow, null))) : (React.createElement(S.ItemLink, { isBold: item.style === 'bold', href: item.url }, item.title)))))));
};
export default NavigationMobile;
function flatMapRecursive(array, iteratee) {
    let result = [];
    array.forEach((item) => {
        result = result.concat(item);
        if (Array.isArray(iteratee(item))) {
            result = result.concat(flatMapRecursive(iteratee(item), iteratee));
        }
    });
    return result;
}
