class UrlResolver {
    _localDomain = 'https://groundforce.local';
    /**
     *
     * Gets the current domain
     */
    getDomain = () => {
        if (`${process.env.NODE_ENV}`.toLowerCase() === 'development') {
            return this._localDomain;
        }
        return '';
    };
}
export default new UrlResolver();
