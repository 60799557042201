import React, { useRef, useState } from 'react';
import { NavigationDropdownMode } from '../../Navigation.types';
import S from './NavigationItem.styles';
const NavigationDropdown = React.lazy(() => import('../NavigationDropdown/NavigationDropdown'));
const NavigationItem = ({ children, dropdown, isPortalHeader, promo, title, url, }) => {
    const [isActive, setIsActive] = useState(false);
    const timeoutRef = useRef(-1);
    return (React.createElement(S.Item, { onMouseEnter: () => {
            if (timeoutRef.current === -1) {
                timeoutRef.current = window.setTimeout(() => setIsActive(true), 100);
            }
        }, onMouseLeave: () => {
            if (timeoutRef.current !== -1) {
                window.clearTimeout(timeoutRef.current);
                timeoutRef.current = -1;
            }
            setIsActive(false);
        }, onKeyDown: (event) => {
            if (event.key.toLowerCase() === 'escape') {
                event.preventDefault();
                event.stopPropagation();
                setIsActive(false);
                return;
            }
        } },
        React.createElement(S.Link, { href: url, isActive: isActive, onKeyDown: (event) => {
                if (event.key.toLowerCase() === 'arrowdown') {
                    event.preventDefault();
                    setIsActive(true);
                    return;
                }
                if (event.key.toLowerCase() === 'arrowup') {
                    event.preventDefault();
                    setIsActive(false);
                    return;
                }
            } }, title),
        dropdown === NavigationDropdownMode.None || dropdown === undefined || isPortalHeader ? (React.createElement(React.Fragment, null)) : (isActive && (React.createElement(React.Suspense, { fallback: null },
            React.createElement(NavigationDropdown, { isActive: isActive, items: children, mode: dropdown, promo: promo }))))));
};
export default NavigationItem;
