import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.section `
  display: flex;
  flex-direction: column;
  margin: 44px 0 0;

  @media ${from(Device.DesktopSmall)} {
    display: none;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #b9b9b9;
    margin: 0 10px 12px;
    width: calc(100% - 20px);
  }
`;
const Link = styled.a `
  display: inline-block;
  color: #575757;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 10px;

  &:focus,
  &:hover {
    color: #575757;
    text-decoration: underline;
  }
`;
export default {
    Container,
    Link,
};
