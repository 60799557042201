/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import styled, { css } from 'styled-components';
const height = 20;
const width = 30;
const Container = styled.div `
  display: block;
`;
const Button = styled.button `
  position: relative;
  display: block;
  background: none;
  border: 0;
  color: #333333;
  height: ${height}px;
  padding: 0;
  margin: 0;
  width: ${width}px;

  &:focus,
  &:hover {
    background: none;
    color: #333333;
  }

  ${({ active }) => active &&
    css `
      ${Line1} {
        transform: translateY(0) scaleX(0);
      }

      ${Line2} {
        transform: translateY(0) scaleX(1) rotate(45deg);
      }

      ${Line3} {
        transform: translateY(0) scaleX(1) rotate(-45deg);
      }
    `}
`;
const Line = styled.span `
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  background: currentColor;
  height: 2px;
  transition: transform 0.15s ease-out;
  width: 100%;
`;
const Line1 = styled(Line) `
  transform: translateY(0) scaleX(1);
`;
const Line2 = styled(Line) `
  transform: translateY(-${height / 2}px);
`;
const Line3 = styled(Line) `
  transform: translateY(${height / 2}px);
`;
export default {
    Button,
    Container,
    Line1,
    Line2,
    Line3,
};
