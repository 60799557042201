import { imageUrl } from '@helpers/imageUrl';
import React from 'react';
import S from './Logo.styles';
const Logo = ({ title, url, logoCloudinaryPath }) => {
    if (!url) {
        return (React.createElement(S.Container, null,
            React.createElement(S.Image, { alt: "", height: "62", loading: "eager", src: imageUrl(logoCloudinaryPath, { width: 297, dpr: 1 }), srcSet: [
                    `${imageUrl(logoCloudinaryPath, { width: 297, dpr: 1 })} 1x`,
                    `${imageUrl(logoCloudinaryPath, { width: 297, dpr: 2 })} 2x`,
                ].join(','), width: "297" })));
    }
    return (React.createElement(S.Container, null,
        React.createElement(S.Link, { href: url, title: title, "aria-label": "Logo" },
            React.createElement(S.Image, { alt: "", height: "62", loading: "eager", src: imageUrl(logoCloudinaryPath, { width: 297, dpr: 1 }), srcSet: [
                    `${imageUrl(logoCloudinaryPath, { width: 297, dpr: 1 })} 1x`,
                    `${imageUrl(logoCloudinaryPath, { width: 297, dpr: 2 })} 2x`,
                ].join(','), width: "297" }))));
};
export default Logo;
