/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import srOnly from '@helpers/srOnly';
import styled, { css } from 'styled-components';
const Container = styled.ul `
  height: 24px;

  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateX(12px);
`;
const Item = styled.li `
  display: block;
  margin: 0 12px;
  padding: 0;
`;
const linkStyles = css `
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: #333333;
  flex-direction: column;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: none;
    border: 0;
    color: #333333;
    text-decoration: none;

    ${() => Label} {
      background-size: 100% 100%;
      color: #fff;
    }

    ${() => Icon} {
      transform: translateY(-4px) scale(1.1);
    }
  }
`;
const Link = styled.a `
  ${linkStyles}
`;
const Button = styled.button.attrs({ type: 'button' }) `
  ${linkStyles}
`;
const Label = styled.span `
  ${srOnly}
`;
const Icon = styled.div `
  margin: 0;
  padding: 0;
  transform: translateY(0);
  transform-origin: center bottom;
  transition: transform 0.15s ease-out;

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`;
export default {
    Button,
    Container,
    Icon,
    Item,
    Label,
    Link,
};
