/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef } from 'react';
const useBodyScrollLock = (trigger) => {
    const ref = useRef(null);
    // useEffect(() => {
    //   if (ref.current === null) {
    //     clearAllBodyScrollLocks();
    //     return;
    //   }
    //   if (trigger === true) {
    //     disableBodyScroll(ref.current);
    //   } else {
    //     enableBodyScroll(ref.current);
    //   }
    //   return () => {
    //     if (ref.current !== null) {
    //       enableBodyScroll(ref.current);
    //     }
    //   };
    // }, [trigger]);
    return ref;
};
export default useBodyScrollLock;
