import ApiService from '@core/api/services/ApiService';
import UrlResolver from '@core/url-resolver/UrlResolver';
class AuthService {
    async authenticate(username, password, cultureCode, type, redirectUrl) {
        const response = ApiService.request({
            controller: 'auth',
            baseUrl: UrlResolver.getDomain(),
            slug: 'authenticate',
            method: 'POST',
            params: {
                username,
                password,
                cultureCode,
                type,
                redirectUrl,
            },
        });
        return response;
    }
    async signOut() {
        const response = ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'auth',
            slug: 'sign-out',
            method: 'GET',
            params: {},
        });
        return response;
    }
    async requestForgotPassword(email, cultureCode) {
        const response = ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            controller: 'auth',
            slug: 'request-forgotten-password',
            method: 'GET',
            params: { email, cultureCode },
        });
        return response;
    }
    async resetPassword(password, cultureCode, hashToken) {
        const response = ApiService.request({
            controller: 'auth',
            baseUrl: UrlResolver.getDomain(),
            slug: 'reset-password',
            method: 'POST',
            params: {
                password,
                cultureCode,
                hashToken,
            },
        });
        return response;
    }
}
export default new AuthService();
