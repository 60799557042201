import brand from '@helpers/brand';
import { Device, until } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.nav `
  display: block;
`;
const List = styled.ul `
  display: flex;
  margin: 0;
  padding: 0;

  @media ${until(Device.DesktopSmall)} {
    display: none;
  }

  &[data-is-portal='true'] {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: ${brand.white};
      height: 100%;
      width: 100vw;
      left: calc(100% + 30px);
      top: 0px;
      clip-path: polygon(1% 0%, 100% 0px, 100% 100%, 0% 100%);
    }
  }
`;
const HomeIcon = styled.a `
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 45px;
  color: #333333;
  font-size: 2em;

  @media ${until(Device.TabletLarge)} {
    margin-bottom: 3px;
  }

  &:hover {
    color: #6f9d20;
  }
`;
const Spacer = styled.li `
  width: 10px;
`;
export default {
    Container,
    List,
    HomeIcon,
    Spacer,
};
