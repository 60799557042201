import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Container = styled.section `
  display: flex;
  background: #f6f6f6;
  flex-direction: row;
`;
const Link = styled.a `
  display: inline-block;
  color: #575757;
  ${fonts.DaxPro.Regular};
  font-size: 14px;
  line-height: 20px;
  padding: 4px 12px;
  position: relative;

  &:focus,
  &:hover {
    color: #575757;
    text-decoration: underline;
  }
`;
export default {
    Container,
    Link,
};
