import React from 'react';
import S from './Navicon.styles';
const Navicon = ({ active, onClick }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Button, { active: active, "aria-label": "Toggle Navigation", type: "button", onClick: (event) => {
                event.preventDefault();
                onClick();
            } },
            React.createElement(S.Line1, null),
            React.createElement(S.Line2, null),
            React.createElement(S.Line3, null))));
};
export default Navicon;
