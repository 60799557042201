import { ReactComponent as SvgCart } from '@img/icons/cart.svg';
import { ReactComponent as SvgSearch } from '@img/icons/search.svg';
import { ReactComponent as SvgUser } from '@img/icons/user.svg';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import S from './IconCtaListMobile.styles';
const IconCtaListMobile = ({ enquiriesCtaUrl, searchText, myProfileText, myProfileUrl, loginText, enquiriesText, isLoggedIn, onSearchClick, }) => {
    const dispatch = useDispatch();
    return (React.createElement(S.Container, null,
        React.createElement(S.Item, null,
            React.createElement(S.Button, { onClick: onSearchClick },
                React.createElement(S.Icon, null,
                    React.createElement(SvgSearch, null)),
                React.createElement(S.Label, null, searchText))),
        !isLoggedIn && (React.createElement(S.Item, null,
            React.createElement(S.Button, { onClick: () => dispatch({
                    type: 'APP/SET_LOGIN_MODAL',
                    payload: {
                        loginType: LoginRedirectType.Default,
                    },
                }) },
                React.createElement(S.Icon, null,
                    React.createElement(SvgUser, null)),
                React.createElement(S.Label, null, loginText)))),
        isLoggedIn && (React.createElement(S.Item, null,
            React.createElement(S.Link, { href: myProfileUrl },
                React.createElement(S.Icon, null,
                    React.createElement(SvgUser, null)),
                React.createElement(S.Label, null, myProfileText)))),
        enquiriesCtaUrl && enquiriesText && (React.createElement(S.Item, null,
            React.createElement(S.Link, { href: enquiriesCtaUrl },
                React.createElement(S.Icon, null,
                    React.createElement(SvgCart, null)),
                React.createElement(S.Label, null, enquiriesText))))));
};
export default IconCtaListMobile;
