import styled from 'styled-components';
const Container = styled.div `
  display: block;
`;
const Link = styled.a `
  display: block;
  color: #333333;

  &:focus {
    color: #333333;
    outline: auto !important;
    outline-offset: 4px;
  }
`;
const Image = styled.img `
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 297px;
  object-fit: contain;
  object-position: center left;
  width: 100%;
`;
export default {
    Container,
    Link,
    Image,
};
